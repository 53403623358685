import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./component/Layout";
import ColorPanel from "./component/ColorPanel";
import { I18Provider, LOCALES } from "./i18n";
import { useCookies } from "react-cookie";

const CompleteYourRing = lazy(() =>
  import("./component/ApplicationPreview/CompleteYourRing")
);
const MinedTab = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/MinedTab")
);
const Lab = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/LabGrown")
);
const FancyTab = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/FancyColor")
);
const Compare = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/Compare")
);
const Request = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/Request")
);

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );

  const [cookies] = useCookies(["lngCookie"]);

  const language = cookies.lngCookie;
  const currentLocale = Object.keys(LOCALES).filter((id) => {
    if (LOCALES[id] === (language ? language.toUpperCase() : LOCALES.ENGLISH))
      return LOCALES[id];
  });

  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <Suspense fallback={""}>
        <I18Provider locale={currentLocale}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/viewDiamondDetails"
                element={<CompleteYourRing />}
              />
              <Route exact path="/" element={<PrivateRoute />}>
                <Route path="/Mined" element={<MinedTab />} />
                <Route path="/Lab" element={<Lab />} />
                <Route path="/Fancy" element={<FancyTab />} />
                <Route path="/Request" element={<Request />} />
                <Route path="/Compare" element={<Compare />} />
              </Route>
            </Routes>
            <ColorPanel />
          </BrowserRouter>
        </I18Provider>
      </Suspense>
    </div>
  );
};

export default App;
